<template>
  <div class="new_school_list_page">
    <div class="bottom_btns">
      <preview-zhiyuan-btn :tableData="tableData"></preview-zhiyuan-btn>
      <buy-vip
        v-if="userInfo && !userInfo.can_query"
        :reload="reload"
        :routePath="routePath"
        :vipType="vipType"
      ></buy-vip>
    </div>
    <!-- <div class="loading_box" v-if="loading">加载数据...</div> -->
    <div>
      <ul class="school_list">
        <li v-for="(item, index) in tableData" :key="index">
          <div class="item">
            <div class="info" @click="viewSchoolDetail(item)">
              <div class="school_info">
                <span class="school_name">{{ item.name }}</span>
              </div>
              <div>
                <span class="schoold_code">院校代码 {{ item.code }}</span>
                <span class="category" v-if="item.category">{{
                  item.category
                }}</span>
                <span class="line">/</span>
                <span class="zone_name" v-if="item.zone_name">{{
                  item.zone_name
                }}</span>
                <span class="line">/</span>
                <span class="zone_name" v-if="item.nature">{{
                  item.nature
                }}</span>
              </div>
              <div class="search_key">{{ item.show_search_key }}</div>
              <!-- 取消学校计划数 -->
              <!-- <div>
                <div>
                  <span>{{ currentYear }}计划数</span
                  ><span>{{ item.people_count }}</span>
                </div>
              </div> -->
            </div>
            <div class="major_btn">
              <div class="num">{{ item.professionals_count }}</div>
              <span class="major_num" @click="toMajorPage(item)">
                匹配专业<i class="el-icon-arrow-right"></i>
              </span>
            </div>
          </div>
        </li>
      </ul>
      <!-- 学校招生详情 -->
      <van-popup
        class="school_modal"
        v-model="schoolDetailPopupShow"
        closeable
        position="bottom"
        :style="{ height: '75%' }"
      >
        <h2>
          {{ schoolDetail.name }}
          <span class="tag_wrap">
            <span v-for="(tag, tindex) in schoolDetail.tag_list" :key="tindex">
              {{ tag }}
            </span>
          </span>
        </h2>
        <div class="tag_wrap">
          <span class="code">代码 {{ schoolDetail.code }}</span>
          <span class="category">{{ schoolDetail.category }}</span>
          <span class="line">/</span>
          <span class="zone_name">{{ schoolDetail.zone_name }}</span>
          <span class="line">/</span>
          <span class="zone_name">{{ schoolDetail.nature }}</span>
        </div>
        <div class="title">{{ currentYear }}招生计划</div>

        <el-table
          class="history_detail"
          :data="schoolDetail.professionals"
          style="width: 100%"
          height="78%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="name" label="专业名称" width="110px">
          </el-table-column>
          <el-table-column label="选科" width="70px">
            <template slot-scope="scope">
              {{ scope.row.require_subjects }}
              <div v-if="!scope.row.can_edit" class="not_pipei_tip">不匹配</div>
            </template>
          </el-table-column>
          <el-table-column label="计划段">
            <template slot-scope="scope">
              {{ scope.row.data[0].new_counts }}
            </template>
          </el-table-column>
          <el-table-column label="学制/学费" width="80px">
            <template slot-scope="scope">
              {{ scope.row.years || "-" }}/{{ scope.row.money || "-" }}
            </template>
          </el-table-column>
        </el-table>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import buyVip from "@/components/buyVip";
import previewZhiyuanBtn from "@/components/previewZhiyuanBtn";

export default {
  components: {
    buyVip,
    previewZhiyuanBtn,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    schoolNowYear: {
      type: String,
      default: "2019",
    },
  },
  data() {
    return {
      vipType: 2,
      subTableData: [],
      schoolDetailPopupShow: false,
      schoolDetail: {},
      majorDetail: {},
      majorDetailPopupShow: false,
      previewPopupShow: false,
      toMajorList: false,
      reload: true,
      routePath: "",
      loading: false,
    };
  },
  watch: {
    tableData: function(newVal) {
      if (newVal) {
        // this.loading = false;
        this.delData();
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      currentType: (state) => state.currentType,
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    }),
  },
  created() {
    // this.loading = true;
    this.routePath = this.$route.path;
  },
  mounted() {
    this.subTableData.forEach((item) => {
      this.$set(item, "isSelect", false);
    });
  },
  methods: {
    delData() {
      this.tableData.forEach((item) => {
        this.$set(item, "isSelect", false);
        this.$set(item, "majorData", []);
      });
    },
    // getMajorData(item) {
    //   this.tableData.forEach(d => {
    //     if (d.id == item.id && d.majorData.length == 0) {
    //       this.getSchoolMajor(item);
    //     }
    //   });
    // },
    //获取专业
    // async getSchoolMajor(item) {
    //   const apiUrl = "/api/school/professional/";
    //   try {
    //     const reqData = {
    //       school_id: item.id,
    //       point: this.userInfo.point,
    //       subjects: this.userInfo.subject_str,
    //       position: this.userInfo.position,
    //       type: ""
    //     };
    //     const response = await this.$axios.post(apiUrl, reqData);
    //     if (response.data.errno === 0) {
    //       this.subTableData = response.data.results.results;
    //       this.subloading = false;
    //       this.dealData(this.subTableData);
    //       //  将专业数据，合并到学校信息中
    //       this.tableData.forEach(d => {
    //         if (d.id == item.id) {
    //           d.majorData = this.subTableData;
    //           localStorage.setItem("currentSchoolData", JSON.stringify(d));
    //         }
    //       });

    //       if (this.toMajorList) {
    //         this.$router.push({
    //           name: "NewTableMajor",
    //           params: { id: item.id }
    //         });
    //       }
    //     }
    //   } catch (error) {
    //     console.log(`question error: ${error}`);
    //   }
    // },
    async toMajorPage(item) {
      if (item.professionals_count > 0) {
        this.toMajorList = true;
        // console.log("7777---", item);
        localStorage.setItem("currentSchoolData", JSON.stringify(item));
        this.$router.push({
          name: "NewTableMajor",
          params: { id: item.id },
        });
      } else {
        this.$toast("没有匹配的专业");
      }
      // await this.getMajorData(item);
    },
    dealData(data) {
      data.forEach((item, index) => {
        if (!item["isSelect"]) {
          this.$set(item, "isSelect", false);
        }
      });
    },
    viewSchoolDetail(item) {
      this.schoolDetail = item;
      this.schoolDetailPopupShow = true;
      // this.getMajorData(item);
    },
    tableRowClassName({ row }) {
      if (!row.can_edit) {
        return "not_pipei";
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
.new_school_list_page {
  background: #efefef;
  padding: 0.3rem;
  .bottom_btns {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0 0.3rem;
    height: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .loading_box {
    text-align: center;
    color: #999;
  }
  ul.school_list {
    > li {
      margin-bottom: 10px;
    }
    li {
      width: 100%;
      text-align: left;
      background: #fff;
      border-radius: 8px;
      color: #999;
      overflow: hidden;
      box-sizing: border-box;
      .item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding: 10px;
        .info {
          flex-basis: 80%;
          padding-left: 5px;
        }
        .schoold_code {
          margin-right: 0.1rem;
        }
        .search_key {
          color: #910000;
          font-size: 0.2rem;
        }
      }

      .school_name {
        color: #333;
        font-weight: 600;
        font-size: 0.3rem;
        margin-bottom: 0.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .line {
        margin: 0 0.1rem;
      }
      .people_count {
        margin-left: 20px;
      }
      .major_btn,
      .major_num {
        display: inline-block;
        width: 66px;
        text-align: center;
        font-size: 0.23rem;
        background: #fff;
      }
      .major_btn {
        .num {
          font-size: 0.25rem;
          color: #910000;
        }
      }
      .select_zhiyuan {
        width: 66px;
        text-align: center;
        color: #910000;
        font-size: 12px;
        margin-top: 2px;
      }
      .selected_major {
        background: #910000;
        color: #fff;
      }
    }
  }

  .school_modal,
  .major_modal {
    text-align: left;
    padding: 15px;
    box-sizing: border-box;
    .not_pipei {
      color: #c3c3c3;
    }
    .not_pipei_tip {
      color: #e4e4e4;
      background: #c3c3c3;
      border-radius: 20px;
      text-align: center;
      font-size: 0.18rem;
      line-height: 16px;
    }
    .tag_wrap {
      font-size: 12px;
      color: #999;
      span {
        margin-right: 5px;
      }
    }
    h2 {
      font-size: 0.3rem;
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 0.1rem;
    }
    .school_name {
      font-size: 12px;
      color: #999;
    }
    .zhaosheng_jihua {
      margin-top: 5px;
      li {
        display: flex;
        height: 34px;
        line-height: 34px;
        justify-content: space-around;
      }
      li:nth-child(1) {
        background: #ececec;
      }
      li:nth-child(2) {
        border-bottom: 1px solid #eeeeee;
      }
    }
    .title {
      margin: 0.2rem 0 0 0;
    }
    .history_detail {
      font-size: 0.2rem;
      th > .cell {
        color: #4c4c4c;
        font-weight: 600;
      }
      tr {
        td:nth-child(2),
        th:nth-child(2),
        td:nth-child(3),
        th:nth-child(3) {
          text-align: center;
        }
      }
    }
  }
}
</style>
