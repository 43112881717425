<template>
  <div class="school_list_page">
    <div class="round_preview_fill_list" @click="viewFillDetail">
      预览<br />志愿
      <span class="num" v-if="fillNum > 0">{{ fillNum }}</span>
    </div>
    <div
      class="fill_buy_vip"
      v-if="userInfo && !userInfo.can_query"
      @click="showModal = true"
    >
      绑定VIP查看全部
    </div>

    <cardBind
      :showModal="showModal"
      @closeModal="hideModal"
      :reload="true"
      :routePath="routePath"
      :vipType="vipType"
    ></cardBind>
    <ul class="school_list">
      <li v-for="(item, index) in tableData" :key="index">
        <div class="item" :class="{ expand_item: item.expand }">
          <div class="percent">{{ item.percent }}%</div>
          <div class="info">
            <div class="school_info">
              <span class="school_name" @click="viewSchoolDetail(item)">{{
                item.name
              }}</span>
              <span class="tag_wrap">
                <span v-for="(tag, tindex) in item.tag_list" :key="tindex">
                  {{ tag }}
                </span>
              </span>
            </div>
            <div>
              <span class="category">{{ item.category }}</span>
              <span class="zone_name">{{ item.zone_name }}</span>
            </div>
            <div>
              <div>
                <span>{{ currentYear }}招生计划</span
                ><span>{{ item.people_count }}</span>
              </div>
              <div>
                <span>{{ item.data[0].year }}最低分</span
                ><span>{{ item.data[0].min_points }}</span>
                <span class="people_count">录取人数 -</span>
              </div>
            </div>
            <div class="search_key">{{item.show_search_key}}</div>
          </div>
          
          <div class="major_btn">
            <span class="major_num" @click="toggleMajor(item, index)">
              专业<i v-if="item.majorData.length > 0">{{
                item.majorData.length
              }}</i>
              <i v-if="!item.expand" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </span>
            <div class="select_zhiyuan" v-if="item.zhiyuan">
              {{ item.zhiyuan }}志愿
            </div>
          </div>
        </div>
        <div class="major_list_wrap">
          <div class="sub_loading" v-if="subLoading && item.expand">
            加载中...
          </div>
          <ul v-if="item.expand" class="major_list">
            <li v-for="(majorItem, mindex) in item.majorData" :key="mindex">
              <div class="item">
                <div class="percent">
                  <div>{{ majorItem.percent }}%</div>
                  <div class="text_intro">专业概率</div>
                </div>
                <div class="info">
                  <div class="school_info">
                    <span
                      class="school_name"
                      @click="viewMajorDetail(item, majorItem)"
                      >{{ majorItem.name }}</span
                    >
                  </div>
                  <div>
                    <span class="category">学制：{{ majorItem.years }}</span>
                    <span class="zone_name">学费：{{ majorItem.money }}</span>
                  </div>
                  <div>
                    <div>
                      <span>{{ currentYear }}招生计划</span
                      ><span>{{ item.people_count }}</span>
                    </div>
                    <div>
                      <span>{{ majorItem.data[0].year }}最低分</span
                      ><span>{{ majorItem.data[0].min_points }}</span>
                      <!-- <span>录取人数 8</span> -->
                    </div>
                  </div>
                </div>
                <div class="major_btn">
                  <span
                    class="major_num"
                    :class="{ selected_major: majorItem.isSelect }"
                    @click="handleClickFillBtn(item, majorItem)"
                  >
                    填报
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <!-- 学校招生详情 -->
    <van-popup
      class="school_modal"
      v-model="schoolDetailPopupShow"
      closeable
      position="bottom"
      :style="{ height: '75%' }"
    >
      <h2>
        {{ schoolDetail.name }}
      </h2>
      <div class="title">{{ currentYear }}招生计划</div>
      <ul class="zhaosheng_jihua">
        <li>
          <span>概率</span>
          <!-- <span>代码</span> -->
          <span>人数</span>
        </li>
        <li>
          <span>{{ schoolDetail.percent }}%</span>
          <!-- <span>{{ schoolDetail.code }}</span> -->
          <span>{{ schoolDetail.people_count }}</span>
        </li>
      </ul>
      <div class="title">院校历年招生统计</div>
      <el-table
        class="history_detail"
        :data="schoolDetail.data"
        style="width: 100%"
      >
        <el-table-column prop="year" label="年份" width="60px">
        </el-table-column>
        <el-table-column label="招生数" width="60px">
          <template slot-scope="scope">
            {{ scope.row.people_count || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="最低分">
          <template slot-scope="scope">
            {{ scope.row.min_points || "-" }}
          </template>
        </el-table-column>

        <el-table-column label="线差">
          <template slot-scope="scope">
            {{ scope.row.gap_points || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="最低位次">
          <template slot-scope="scope">
            {{ scope.row.min_position || "-" }}
          </template>
        </el-table-column>
      </el-table>
    </van-popup>

    <!-- 专业招生详情 -->
    <van-popup
      class="major_modal"
      v-model="majorDetailPopupShow"
      closeable
      position="bottom"
      :style="{ height: '75%' }"
    >
      <h2>
        {{ majorDetail.name }}
      </h2>
      <div class="school_name">
        {{ schoolDetail.name }}
      </div>
      <div class="title">{{ currentYear }}招生计划</div>
      <ul class="zhaosheng_jihua">
        <li>
          <span>录取概率</span>
          <span>代码</span>
          <span>人数</span>
          <span>学费</span>
          <span>学制</span>
        </li>
        <li>
          <span>{{ majorDetail.percent }}%</span>
          <span>{{ majorDetail.code }}</span>
          <span>{{ majorDetail.people_count }}</span>
          <span>{{ majorDetail.money }}</span>
          <span>{{ majorDetail.years }}</span>
        </li>
      </ul>
      <div class="title">专业历年招生统计</div>
      <el-table
        class="history_detail"
        :data="majorDetail.data"
        style="width: 100%"
      >
        <el-table-column prop="year" label="年份" width="60px">
        </el-table-column>
        <el-table-column label="录取数" width="60px">
          <template slot-scope="scope">
            {{ scope.row.people_count || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="最低分">
          <template slot-scope="scope">
            {{ scope.row.min_points || "-" }}
          </template>
        </el-table-column>

        <el-table-column label="线差">
          <template slot-scope="scope">
            {{ scope.row.gap_points || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="最低位次">
          <template slot-scope="scope">
            {{ scope.row.min_position || "-" }}
          </template>
        </el-table-column>
      </el-table>
    </van-popup>

    <!-- 预览志愿详情 -->
    <van-popup
      class="preview_modal"
      v-model="previewPopupShow"
      closeable
      position="bottom"
      :style="{ height: '82%' }"
    >
      <h2 v-if="schoolDetail.name">{{ schoolDetail.name }}</h2>
      <h2 v-else></h2>
      <div>放置在哪个志愿呢？</div>
      <div class="tip">
        <div>1、可替换已填院校</div>
        <div>2、拖动右侧小图标可改变院校位置</div>
      </div>
      <div class="fill_school_list">
        <div class="cover_box">
          <ul class="fill_list">
            <li
              class="item"
              v-for="(item, index) in fillDataList"
              :key="index"
              @click="fillZhiyuanList(item)"
            >
              <div>
                <span class="key">{{ item.key }}</span>
                <span>{{ item.name || "点击填报" }}</span>
              </div>
              <div class="major_order_wrap">
                {{ item.majorIds.length }}个专业
              </div>
            </li>
          </ul>
        </div>
        <draggable
          class="list"
          v-model="fillDataList"
          :options="{
            group: 'layout',
            draggable: '.item',
            sort: true, // 内部排序列表
            animation: 50
          }"
          @update="datadragEnd"
        >
          <transition-group tag="ul" class="fill_list">
            <li
              class="item"
              v-for="(item, index) in fillDataList"
              :key="index"
              @click="fillZhiyuanList(item)"
            >
              <div class="drag_school_name">
                <span class="key">{{ item.key }}</span>
                <span>{{ item.name || "点击填报" }}</span>
              </div>
              <div class="major_order_wrap">
                <span>{{ item.majorIds.length }}个专业</span>
                <i class="el-icon-s-fold"></i>
              </div>
            </li>
          </transition-group>
        </draggable>
      </div>
      <el-button type="primary" class="create_zhiyuan" @click="createMyTable"
        >生成志愿</el-button
      >
    </van-popup>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import cardBind from "@/components/cardBind";

export default {
  components: { draggable, cardBind },
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fillNum: 0,
      routePath: "",
      vipType: 2,
      subLoading: false,
      subTableData: [],
      schoolDetailPopupShow: false,
      schoolDetail: {},
      majorDetail: {},
      majorDetailPopupShow: false,
      previewPopupShow: false,
      zhiyuanInfo: {},
      fillDataList: [], //志愿表
      showModal: false,
      zhiyuanLabelArray: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear
      // isAuthed: state => (state.userinfo ? true : false),
    })
  },
  watch: {
    userInfo: function(newVal) {
      if (
        newVal &&
        !newVal.is_new &&
        Object.keys(this.zhiyuanInfo).length == 0
      ) {
        this.getZhiyuanInfo();
      }
    },
    fillDataList: {
      deep: true,
      handler: function(val) {
        this.selectSchoolNumber(val);
      }
    },
  },
  created() {
    this.routePath = this.$route.path;
    if (
      this.userInfo &&
      !this.userInfo.is_new &&
      this.fillDataList.length == 0
    ) {
      this.getZhiyuanInfo();
    }

   
  },
  methods: {
    selectSchoolNumber(val) {
      this.fillNum = 0;
      val.forEach(item => {
        if (item.name && (item.majorIds.length > 0 || item.number > 0)) {
          this.fillNum += 1;
        }
      });
    },
    hideModal() {
      this.showModal = false;
    },
    toggleMajor(item, index) {
      item.expand = !item.expand;
      localStorage.setItem("allSchoolData", JSON.stringify(this.tableData));
      if (item.expand) {
        this.getMajorData(item);
      }
    },
    getMajorData(item) {
      this.tableData.forEach(d => {
        if (d.id == item.id && d.majorData.length == 0) {
          this.getSchoolMajor(item);
        }
      });
    },
    //获取专业
    async getSchoolMajor(item) {
      this.subLoading = true;
      const apiUrl = "/api/school/professional/";
      try {
        const reqData = {
          school_id: item.id,
          point: this.userInfo && this.userInfo.point,
          type: this.userInfo && this.userInfo.type,
          subjects: ""
        };
        const response = await this.$axios.post(apiUrl, reqData);
        if (response.data.errno === 0) {
          this.subTableData = response.data.results.results;
          this.dealData(this.subTableData);
          //  将专业数据，合并到学校信息中
          this.tableData.forEach(d => {
            if (d.id == item.id) {
              d.majorData = this.subTableData;
            }
          });
          item.majorData = this.subTableData;

          this.subLoading = false;
        } else {
          this.subLoading = false;
        }
        localStorage.setItem("allSchoolData", JSON.stringify(this.tableData));
      } catch (error) {
        this.subLoading = false;
        console.log(`question error: ${error}`);
      }
    },
    dealData(data) {
      data.forEach((item, index) => {
        if (!item["isSelect"]) {
          this.$set(item, "isSelect", false);
        }
      });
    },
    viewSchoolDetail(item) {
      this.schoolDetail = item;
      this.schoolDetailPopupShow = true;
    },
    viewMajorDetail(item, majorItem) {
      this.schoolDetail = item;
      console.log(this.schoolDetail);
      this.majorDetail = majorItem;
      this.majorDetailPopupShow = true;
    },
    handleClickFillBtn(item, majorItem) {
      this.schoolDetail = item;
      this.majorDetail = majorItem;
      if (
        this.userInfo &&
        this.userInfo.province == "辽宁" &&
        this.limitSelectNum() >= 6
      ) {
        if (!majorItem.isSelect) {
          this.$toast("选择专业已达到上限（6个）");
          return false;
        }
      }

      if (this.fillDataList.length > 0) {
        const flag = this.fillDataList.find(el => {
          return el.name == item.name;
        });
        if (!flag) {
          this.previewPopupShow = true;
        }

        // console.log('专业详情---',this.majorDetail);

        this.fillDataList.forEach(el => {
          if (el.name == item.name) {
            this.majorDetail.isSelect = !this.majorDetail.isSelect;
            if (
              this.majorDetail.isSelect &&
              !el.majorIds.includes(this.majorDetail.id)
            ) {
              el.majorIds.push(this.majorDetail.id);
            } else {
              el.majorIds.forEach((mid, idIndex) => {
                if (this.majorDetail.id == mid) {
                  el.majorIds.splice(idIndex, 1);
                }
              });

              if (el.majorIds.length == 0) {
                this.schoolDetail.zhiyuan = "";
                el.name = "";
                el.zhiyuan = "";
              }
            }
          }
        });
        // console.log("--fillDataList--", this.fillDataList);

        this.getChooseSchool();
      } else {
        if (!majorItem.isSelect) {
          this.previewPopupShow = true;
        }
      }
    },
    //设置预览数据
    getChooseSchool() {
      const Data = JSON.parse(localStorage.getItem("allSchoolData"))|| [];
      if (Data) {
        Data.forEach((l, lindex) => {
          this.tableData.forEach(t => {
            if (l.id == t.id) {
              Data[lindex] = t;
            }
          });
        });

        localStorage.setItem("allSchoolData", JSON.stringify(Data));
        const newData = JSON.parse(localStorage.getItem("allSchoolData"))|| [];

        newData.forEach(item => {
          this.fillDataList.forEach(l => {
            if (item.zhiyuan && item.zhiyuan == l.label) {
              l.majorIds = [];
              let num = 0;
              item.majorData.forEach(m => {
                if (m.isSelect) {
                  num++;
                  l.majorIds.push(m.id);
                }
              });
              l.name = item.name;
              l.number = num;
              if (num == 0) {
                this.schoolDetail.zhiyuan = "";
                l.name = "";
                l.zhiyuan = "";
              }
            }
          });
        });

        console.log("555555", this.fillDataList);
      }
    },
    limitSelectNum() {
      let selectNum = 0;
      this.schoolDetail.majorData.forEach(m => {
        if (m.isSelect) {
          selectNum += 1;
        }
      });
      return selectNum;
    },
    viewFillDetail() {
      this.previewPopupShow = !this.previewPopupShow;
    },
    fillZhiyuanList(item) {
      // console.log("点击--", this.fillDataList);
      // console.log("this.majorDetail--", this.majorDetail);
      // console.log("this.schoolDetail----", this.schoolDetail);

      this.fillDataList.forEach(el => {
        if (
          el.name == this.schoolDetail.name &&
          item.name != this.schoolDetail.name
        ) {
          el.name = "";
          el.majorIds = [];
          this.schoolDetail.majorData.forEach(major => {
            major.isSelect = false;
          });
        }
      });

      if (this.majorDetail && this.majorDetail.name) {
        this.majorDetail.isSelect = true;
        if (item.name) {
          //有学校
          item.majorIds = [];
          this.tableData.forEach(school => {
            if (item.name == school.name) {
              school.isSelect = false;
              school.zhiyuan = "";
              school.majorData.forEach(major => {
                major.isSelect = false;
              });
            }
          });
        }
        item.id = this.schoolDetail.id;
        item.name = this.schoolDetail.name;
        this.schoolDetail.zhiyuan = item.key;

        const hasId = item.majorIds.includes(this.majorDetail.id);
        if (!hasId) {
          item.majorIds.push(this.majorDetail.id);
        }

        localStorage.setItem("allSchoolData", JSON.stringify(this.tableData));
      } else if (!this.majorDetail.name) {
        this.$toast("请选择专业");
      }
    },

    datadragEnd(evt) {
      //   console.log("拖动前的索引 :" + evt.oldIndex);
      //   console.log("拖动后的索引 :" + evt.newIndex);
      // 重置志愿位次
      this.fillDataList.forEach((i, iIndex) => {
        i.key = this.zhiyuanLabelArray[iIndex].key;
        i.id = this.zhiyuanLabelArray[iIndex].id;
        this.tableData.forEach(item => {
          if (i.name == item.name) {
            item.zhiyuan = i.key;
          }
        });
      });
      // console.log(this.fillDataList);
      // console.log(this.tableData);
    },
    async getZhiyuanInfo() {
      this.dropdownMenu = [];
      this.fillDataList = [];
      this.zhiyuanLabelArray = [];
      const apiUrl = `/api/province/volunteer/?province=${this.userInfo.province}`;
      try {
        const response = await this.$axios.get(apiUrl);
        if (response.data.errno === 0 && response.data.results.length > 0) {
          // 填报限制
          this.zhiyuanInfo = response.data.results[0];
          this.generateBig(this.zhiyuanInfo.school_count).forEach(item => {
            let fillData = [];
            fillData.push({
              name: "",
              key: item,
              label: item,
              number: 0,
              majorIds: []
            });

            this.zhiyuanLabelArray.push({
              key: item,
              id: item
            });
            this.fillDataList = _.unionBy(this.fillDataList, fillData, "key");
          });

          await this.getChooseSchool();
          this.selectSchoolNumber(this.fillDataList);
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    // 生成大写字母ABC
    generateBig(num) {
      var str = [];
      for (var i = 65; i < 65 + num; i++) {
        str.push(String.fromCharCode(i));
      }
      return str;
    },
    //生成志愿表
    async createMyTable() {
      console.log("fillDataList----", this.fillDataList);
      if (this.userInfo && this.userInfo.id) {
        let Num = 0;
        this.fillDataList.forEach(item => {
          if (
            (item.name && item.majorIds && item.majorIds.length > 0) ||
            item.number > 0
          ) {
            //已报志愿
            Num++;
          }
        });

        if (Num < 3) {
          this.$toast("请至少填报三个志愿");
          return;
        }
        const apiUrl = "/api/volunteer/";
        const postData = {
          tag: this.$route.params.key,
          data: this.tableData
        };
        // console.log("postData", postData);
        this.$toast("志愿表生成中...");
        this.previewPopupShow = false;
        try {
          const res = await this.$axios.post(apiUrl, postData);
          if (res.data.errno === 0) {
            this.$toast("志愿表已生成");
            setTimeout(() => {
              this.$router.push({ path: "/myCenter" });
            }, 1000);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$router.push({ name: "login" });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
.school_list_page {
  min-height: 100vh;
  background: #efefef;
  padding: 0.3rem;
  .round_preview_fill_list {
    position: fixed;
    font-size: 13px;
    width: 1.2rem;
    height: 1.2rem;
    background: #910000;
    color: #fff;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    bottom: 1.4rem;
    right: 20px;
    .num {
      display: inline-block;
      width: 0.38rem;
      height: 0.38rem;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #da0600;
      color: #910000;
      font-size: 0.2rem;
      position: absolute;
      right: 0;
      top: -0.12rem;
      text-align: center;
      line-height: 0.38rem;
    }
  }
  .fill_buy_vip {
    position: fixed;
    font-size: 13px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.8rem;
    background: #910000;
    color: #fff;
    padding: 0 0.34rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
  }
  ul.school_list {
    > li {
      margin-bottom: 10px;
    }
    li {
      text-align: left;
      min-height: 100px;
      background: #fff;
      border-radius: 8px;
      color: #999;
      overflow: hidden;
      box-sizing: border-box;
      .item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding: 10px;
        .percent {
          flex-basis: 17%;
          text-align: center;
          color: #910000;
          font-size: 0.3rem;
          font-weight: 600;
        }
        .text_intro {
          color: #999;
          font-size: 0.18rem;
          font-weight: normal;
        }
        .info {
          flex-basis: 80%;
          padding-left: 5px;
        }
      }
      .expand_item {
        border-bottom: 1px solid #ccc;
      }
      .school_name {
        color: #333;
        font-weight: 600;
        font-size: 0.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .zone_name {
        margin-left: 10px;
      }
      .people_count {
        margin-left: 20px;
      }
      .search_key{
        color: #910000;;
      }
      .major_num {
        display: inline-block;
        border-radius: 15px;
        border: 1px solid #ddd;
        width: 66px;
        text-align: center;
        background: #fff;
        padding: 1px 0;
        font-size: 0.2rem;
      }
      .select_zhiyuan {
        width: 66px;
        text-align: center;
        color: #910000;
        font-size: 12px;
        margin-top: 2px;
      }
      .selected_major {
        background: #910000;
        color: #fff;
      }
    }
  }
  .major_list_wrap {
    max-height: 300px;
    overflow-y: scroll;
    .sub_loading {
      text-align: center;
      height: 0.8rem;
      line-height: 0.8rem;
      color: #999;
    }
  }
  ul.major_list {
    font-size: 0.22rem;
    li {
      border-radius: 0px;
      border-bottom: 1px solid #ccc;
      &:last-child {
        border: none;
      }
      .school_name {
        color: #e04a00;
        font-size: 0.26rem;
      }
    }
  }
  .school_modal,
  .major_modal {
    text-align: left;
    padding: 15px;
    box-sizing: border-box;
    h2 {
      font-size: 18px;
      margin-bottom: 0;
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .school_name {
      font-size: 12px;
      color: #999;
    }
    .zhaosheng_jihua {
      margin-top: 5px;
      li {
        display: flex;
        height: 34px;
        line-height: 34px;
        justify-content: space-around;
      }
      li:nth-child(1) {
        background: #ececec;
      }
      li:nth-child(2) {
        border-bottom: 1px solid #eeeeee;
      }
    }
    .title {
      margin: 20px 0 10px 0;
    }
    .history_detail {
      font-size: 0.2rem;
      th > .cell {
        color: #4c4c4c;
        font-weight: 600;
      }
    }
  }

  .preview_modal {
    text-align: left;
    padding: 15px;
    box-sizing: border-box;
    -webkit-touch-callout: none; /*系统默认菜单被禁用*/
    -webkit-user-select: none; /*webkit浏览器*/
    -khtml-user-select: none; /*早期浏览器*/
    -moz-user-select: none; /*火狐*/
    -ms-user-select: none; /*IE10*/
    user-select: none;

    h2 {
      color: #910000;
      font-size: 18px;
      margin-bottom: 4px;
      min-height: 0.6rem;
    }
    .tip {
      color: #999;
      font-size: 12px;
    }
    .fill_school_list {
      height: 60%;
      overflow-y: scroll;
      margin-top: 0.4rem;
      padding-bottom: 0.4rem;
      position: relative;
      .cover_box {
        position: absolute;
        left: 0;
        top: 0;
        width: 90%;
        background: #fff;
        z-index: 10;
      }
      .el-icon-s-fold {
        margin-left: 10px;
        font-size: 20px;
        color: #b9b9b9;
      }
      .major_order_wrap {
        color: #888;
        span {
          margin-right: 1rem;
        }
      }
    }
    .el-button.create_zhiyuan {
      width: 100%;
      background: #910000;
      border-color: #910000;
      margin-top: 30px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;
      z-index: 100;
    }
    ul.fill_list {
      li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        height: 0.78rem;
        border-bottom: 1px solid #ececec;
        &:last-child {
          border: none;
        }
        .drag_school_name {
          flex-basis: 60%;
        }
        .key {
          display: inline-block;
          width: 22px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          background: #910000;
          color: #fff;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
