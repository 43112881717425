<template>
  <div class="filter_component popup_wrap">
    <van-popup
      v-model="popShow"
      position="right"
      style="width: 85%; height: 100vh;"
      @click-overlay="closePopup"
    >
      <div class="popup_content">
        <div class="filter_wrap">
          <div class="arears_wrap">
            <div class="clearfix">
              <div class="label_text">所在地区：<span>(多选)</span></div>
              <ul class="areas">
                <li>
                  <el-checkbox-group
                    v-model="checkCityboxGroup"
                    size="medium"
                    @change="cityChange"
                  >
                    <el-checkbox
                      v-for="city in areasList"
                      :label="city.name"
                      :key="city.name"
                    >
                      <span
                        class="select_option"
                        v-if="city.name == '不限'"
                        :class="{ active_label: activeArea == '不限' }"
                        >{{ city.name }}</span
                      >
                      <span v-else class="select_option">{{ city.name }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </li>
              </ul>
            </div>
          </div>
          <div class="hot_label_wrap" v-if="userInfo && !userInfo.is_new">
            <div class="clearfix">
              <div class="label_text">热门标签：<span>(多选)</span></div>
              <ul class="hot_label">
                <li>
                  <el-checkbox-group
                    v-model="checkHotLabelboxGroup"
                    size="medium"
                    @change="hotLableChange"
                  >
                    <el-checkbox
                      v-for="city in schoolTagList"
                      :label="city.name"
                      :key="city.name"
                    >
                      <span
                        class="select_option"
                        v-if="city.name == '不限'"
                        :class="{ active_label: activeHotLabel == '不限' }"
                        >{{ city.name }}</span
                      >
                      <span v-else class="select_option">{{ city.name }}</span>
                    </el-checkbox>
                  </el-checkbox-group>
                </li>
              </ul>
            </div>
          </div>
          <div class="school_type_wrap" v-if="userInfo && !userInfo.is_new">
            <div class="clearfix">
              <div class="label_text">院校性质： <span>(单选)</span></div>
              <ul class="school_type school_xingzhi">
                <li>
                  <el-checkbox-group
                    v-model="checkSchoolXingzhiboxGroup"
                    @change="schoolXingzhiLableChange"
                  >
                    <el-checkbox
                      v-for="city in natureTagList"
                      :label="city.name"
                      :key="city.name"
                    >
                      <span
                        class="select_option"
                        v-if="city.name == '不限'"
                        :class="{ active_label: activeSchoolNature == '不限' }"
                        >{{ city.name }}</span
                      >
                      <span v-else class="select_option">{{
                        city.name
                      }}</span></el-checkbox
                    >
                  </el-checkbox-group>
                </li>
              </ul>
            </div>
          </div>
          <div class="school_type_wrap">
            <div class="clearfix">
              <div class="label_text">院校类型：<span>(多选)</span></div>
              <ul class="school_type">
                <li>
                  <el-checkbox-group
                    v-model="checkSchoolTypeboxGroup"
                    size="medium"
                    @change="schoolTypeChange"
                  >
                    <el-checkbox
                      v-for="city in categoryTagList"
                      :label="city.name"
                      :key="city.name"
                      ><span
                        class="select_option"
                        v-if="city.name == '不限'"
                        :class="{ active_label: activeSchoolType == '不限' }"
                        >{{ city.name }}</span
                      >
                      <span v-else class="select_option">{{
                        city.name
                      }}</span></el-checkbox
                    >
                  </el-checkbox-group>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
      <div class="btns">
          <el-button type="default" class="reset_btn" @click="handClickReset">重置</el-button>
          <el-button type="primary" @click="confirmFilter">确定</el-button>
        </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "filtersBoard",
  props: {
    showPopup: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear,
      isNew: state => state.isNew
    })
  },
  data() {
    return {
      popShow: false,
      showSubNav: false,
      activeArea: "不限",
      activeHotLabel: "不限",
      activeSchoolNature: "不限",
      activeSchoolType: "不限",
      areasList: [],
      schoolTagList: [],
      natureTagList: [],
      categoryTagList: [],
      checkCityboxGroup: [], //城市
      checkHotLabelboxGroup: [], //热门标签
      checkSchoolXingzhiboxGroup: [], //院校性质
      checkSchoolTypeboxGroup: [] //院校类型
    };
  },
  watch: {
    showPopup: function() {
      this.popShow = this.showPopup;
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    closePopup() {
      this.$emit("update:showPopup", false);
    },
    async initData() {
      const provinceTagApiUrl = "/api/province/all/"; //区域
      const schoolTagApiUrl = "/api/school/tag/"; //获取院校
      const natureTagApiUrl = "/api/school/tag/nature/"; //获取院校性质
      const categoryTagApiUrl = "/api/school/category/"; //获取院校类型

      const [
        provinceTagResponse,
        schoolTagResponse,
        natureTagResponse,
        categoryTagResponse
      ] = await Promise.all([
        this.$axios.get(provinceTagApiUrl),
        this.$axios.get(schoolTagApiUrl),
        this.$axios.get(natureTagApiUrl),
        this.$axios.get(categoryTagApiUrl)
      ]);

      if (provinceTagResponse.data.errno == 0) {
        this.areasList = provinceTagResponse.data.results;
        this.areasList.unshift({
          name: "不限",
          id: "all"
        });
      }

      if (schoolTagResponse.data.errno === 0) {
        this.schoolTagList = schoolTagResponse.data.results.results;
        this.schoolTagList.unshift({
          name: "不限",
          id: "all"
        });
      }

      if (natureTagResponse.data.errno === 0) {
        this.natureTagList = natureTagResponse.data.results.data;
        this.natureTagList.unshift({
          name: "不限",
          id: "all"
        });
      }

      if (categoryTagResponse.data.errno === 0) {
        this.categoryTagList = categoryTagResponse.data.results.results;
        this.categoryTagList.unshift({
          name: "不限",
          id: "all"
        });
      }
    },
    selectAllLabel(type) {
      switch (type) {
        case "city":
          this.activeArea = "不限";
          this.checkCityboxGroup = [];
          // this.fetchSchoolsDataList();
          break;
        case "hot":
          this.activeHotLabel = "不限";
          this.checkHotLabelboxGroup = [];
          // this.fetchSchoolsDataList();
          break;
        case "xingzhi":
          this.activeSchoolNature = "不限";
          this.checkSchoolXingzhiboxGroup = [];
          // this.fetchSchoolsDataList();
          break;
        case "type":
          this.activeSchoolType = "不限";
          this.checkSchoolTypeboxGroup = [];
          // this.fetchSchoolsDataList();
          break;
      }
    },
    cityChange() {
      this.activeArea = "";
      if (this.checkCityboxGroup.length == 0) {
        this.activeArea = "不限";
      } else {
        if (
          this.checkCityboxGroup[this.checkCityboxGroup.length - 1] == "不限"
        ) {
          this.selectAllLabel("city");
        }
      }

      // this.fetchSchoolsDataList();
    },
    schoolTypeChange() {
      this.activeSchoolType = "";
      if (this.checkSchoolTypeboxGroup.length == 0) {
        this.activeSchoolType = "不限";
      } else {
        if (
          this.checkSchoolTypeboxGroup[
            this.checkSchoolTypeboxGroup.length - 1
          ] == "不限"
        ) {
          this.selectAllLabel("type");
        }
      }
      // this.fetchSchoolsDataList();
    },
    hotLableChange() {
      this.activeHotLabel = "";
      if (this.checkHotLabelboxGroup.length == 0) {
        this.activeHotLabel = "不限";
      } else {
        if (
          this.checkHotLabelboxGroup[this.checkHotLabelboxGroup.length - 1] ==
          "不限"
        ) {
          this.selectAllLabel("hot");
        }
      }
      // this.fetchSchoolsDataList();
    },
    schoolXingzhiLableChange(item) {
      let newLabel = item[item.length-1];
      this.activeSchoolNature = "";
      this.checkSchoolXingzhiboxGroup = [];
      this.checkSchoolXingzhiboxGroup.push(newLabel);
      console.log(this.checkSchoolXingzhiboxGroup);
      // this.fetchSchoolsDataList();
    },
    // 重置
    handClickReset() {
      this.selectAllLabel("city");
      this.selectAllLabel("hot");
      this.selectAllLabel("xingzhi");
      this.selectAllLabel("type");
    },
    confirmFilter() {
      console.log("城市选择", this.checkCityboxGroup);
      console.log("热门标签选择", this.checkHotLabelboxGroup);
      console.log("学校类型选择", this.checkSchoolTypeboxGroup);
      console.log("学校性质选择", this.checkSchoolXingzhiboxGroup);
      this.closePopup();
      const filtersData = {
        city: this.checkCityboxGroup,
        hot: this.checkHotLabelboxGroup,
        type: this.checkSchoolTypeboxGroup,
        xingzhi: this.checkSchoolXingzhiboxGroup[0]
      };
      this.$emit("filtersData", filtersData);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.filter_component.popup_wrap {
  .van-overlay{
    background: rgba(26, 26, 26, 0.5) ;
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
  }
  a {
    color: #910000;
  }
}

.popup_wrap {
  font-size: 0.26rem;
  .popup_content {
    overflow: scroll;
    position: relative;
    height: 100vh;
  }
  .btns {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .el-button {
      flex-basis: 50%;
      border-radius: 0;
      margin: 0;
      border: none;
      padding: 14px 20px;
    }
    .el-button--default:focus,
    .el-button--default:hover {
      color: #606266;
    }
    .el-button--default {
      background: #eaeaea;
    }
  }
  .van-popup--left {
    width: 75%;
    height: 100%;
    overflow: hidden;
    background: #fff;
  }

  .filter_wrap {
    padding: 0.2rem 0.3rem 1.8rem 0.3rem;
    .el-checkbox,
    .el-radio {
      margin-right: 0.2rem;
      margin-bottom: 4px;
      width: 29.7%;
    }
    .school_xingzhi {
      .el-radio__input {
        display: none;
      }
    }

    .el-radio-group {
      width: 100%;
    }
    .el-checkbox__input.is-checked,
    .el-checkbox__inner {
      display: none;
    }
    .el-checkbox__label {
      padding-left: 0;
      width: 100%;
    }
    .select_option {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 0.5rem;
      font-size: 0.24rem;
      align-items: center;
      border-radius: 4px;
      background: #ececec;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label,
    .el-radio__input.is-checked + .el-radio__label {
      color: #fff;

      .select_option {
        background: #910000 !important;
      }
    }
    .el-radio__label {
      display: flex;
      padding-left: 0;
    }

    .label_text {
      font-size: 0.25rem;
      margin-bottom: 0.1rem;
      color: #999;
      margin-top: 0.3rem;
    }
    ul {
      li {
        color: #555;
        cursor: pointer;
        float: left;
        width: 100%;
      }
      li:nth-child(2) {
        width: 90%;
        .el-checkbox-group {
          label {
            margin-bottom: 5px;
            &:hover {
              color: #910000;
            }
          }
        }
      }
    }

    span.active_label {
      background: #910000;
      color: #fff;
    }

    .clearfix:after {
      /*伪元素是行内元素 正常浏览器清除浮动方法*/
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
}
.van-overlay {
  background: rgba(26, 26, 26, 0.5);
}
</style>
