<template>
  <div
    class="fill_table_page"
    :class="{
      vip_fill_page: userInfo && userInfo.can_query,
      vip_new_fill_page: userInfo && userInfo.can_query && userInfo.is_new
    }"
  >
    <div class="fill_page_head" v-if="userInfo && !userInfo.is_new">
      <span>{{ pagination.total }}所大学</span>
      <span @click="editScore"
        >{{ userInfo.point }}分<i class="el-icon-edit"></i
      ></span>
      <span @click="editPici"
        >{{ showScoreInfo.pici }}<i class="el-icon-arrow-down"></i>
      </span>
      <!-- <span v-if="!userInfo.is_new"> 线差：{{ showScoreInfo.xiancha }} </span> -->
      <span @click="showFilterPopup">筛选</span>
    </div>

    <div class="fill_page_head" v-if="userInfo && userInfo.is_new">
      <div class="new_head">
        <span>{{ pagination.total }}所大学</span>
        <div @click="editScore">
          <span>{{ userInfo.point }}分</span>
          <span>{{ userInfo.position }} 位</span>
          <span>{{ userInfo.subject_str }} <i class="el-icon-edit"></i></span>
        </div>
      </div>
      <span @click="showFilterPopup">筛选</span>
    </div>
    <div class="content">
      <div class="filter_box" v-if="userInfo">
        <van-radio-group
          v-model="filterValue"
          class="filter_type"
          @change="searchTypeChange"
        >
          <van-radio name="1" icon-size="18px">搜院校</van-radio>
          <van-radio name="2" icon-size="18px">搜专业</van-radio>
        </van-radio-group>
        <el-input
          class="search_value"
          v-model="searchValue"
          :placeholder="'请输入' + searchPlaceholder"
          @change="searchValChange"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
            @click="searchData"
          ></i>
        </el-input>
      </div>
      <div class="table_wrap">
        <filtersBoard
          :showPopup.sync="filterPopupShow"
          @filtersData="getFilters"
        ></filtersBoard>
        <!-- table -->

        <fill-table-component
          v-if="$route.params.key != 'new'"
          :tableData="tableData"
          :showScoreInfo="showScoreInfo"
        ></fill-table-component>
        <!-- 新高考 -->
        <fill-new-gaokao-table
          v-else
          :tableData="tableData"
          :schoolNowYear="schoolNowYear"
        ></fill-new-gaokao-table>
      </div>
      <!-- <div v-if="tableData.length >= 5 && !hasNext" class="over_text">
        到底啦~
      </div> -->
      <div
        v-if="hasNext && userInfo && userInfo.can_query"
        class="more_data"
        :class="{ new_more_data: userInfo && userInfo.is_new }"
        @click="handleClickMore"
      >
        点击加载更多
      </div>
    </div>
    <van-overlay :show="maskShow">
      <van-loading type="spinner" color="#e27979" />
    </van-overlay>
  </div>
</template>

<script>
import fillTableComponent from "@/components/fillTable";
import { mapState } from "vuex";
import fillNewGaokaoTable from "@/components/fillNewGaokaoTable";
import filtersBoard from "@/components/filtersBoard";

export default {
  components: {
    fillTableComponent,
    fillNewGaokaoTable,
    filtersBoard
  },
  data() {
    return {
      maskShow: false,
      pagination: {
        currentPage: 1,
        pageSize: 5,
        total: 0
      },
      hasNext: false,
      piciInfo: {},
      showScoreInfo: {},
      scoreInfo: {
        type: ""
      },
      tableData: [],
      piciTag: "",
      schoolNowYear: "",
      schoolYears: [],
      filterPopupShow: false,
      filtersOption: null,
      isFilter: false,
      filterValue: "1",
      searchValue: "",
      searchPlaceholder: ""
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    })
  },
  watch: {
    userInfo: function(newVal) {
      if (newVal) {
        this.scoreInfo.type = newVal.type;
      }
      if (newVal && this.schoolYears && this.schoolYears.length == 0) {
        this.getSchoolYears();
      }
      if (newVal) {
        if (!!localStorage.getItem("allSchoolData")) {
          this.tableData = JSON.parse(localStorage.getItem("allSchoolData"))|| [];
        } else {
          this.fetchSchoolsDataList();
        }
      }
    },
    tableData: function(newVal) {
      if (newVal) {
        this.maskShow = false;
      }
    }
  },
  created() {
    this.maskShow = true;
    this.hasNext =
      localStorage.getItem("localHasNext") == "true" ? true : false;
    this.pagination.currentPage = !!localStorage.getItem("localPage")
      ? localStorage.getItem("localPage") * 1
      : 1;
    this.pagination.total = localStorage.getItem("localTotalPage")
      ? localStorage.getItem("localTotalPage") * 1
      : 0;
  },
  async mounted() {
    this.piciTag = this.$route.params.key;
    if (this.$route.params.key != "new") {
      // await this.getScorePiciTuijian();
      this.showScoreInfo.pici = this.$route.params.key;
      this.scoreInfo.type = this.userInfo && this.userInfo.type;
    } else {
      //新高考不需要批次
      localStorage.removeItem("scoreInfoData");
    }
    if (this.piciInfo) {
      for (let key in this.piciInfo.detail) {
        if (key == this.$route.params.key) {
          for (let name in this.piciInfo.detail[key]) {
            if (name == this.scoreInfo.type) {
              this.showScoreInfo.xiancha =
                this.scoreInfo.point - this.piciInfo.detail[key][name];
            }
          }
        }
      }
    }
  },
  methods: {
    searchTypeChange(val) {
      console.log(val);
      if (val == 1) {
        this.searchPlaceholder = "院校";
        this.searchValue = "";
      } else {
        this.searchPlaceholder = "专业";
        this.searchValue = "";
      }
    },
    searchValChange(val) {
      console.log(this.searchValue);
    },
    showFilterPopup() {
      this.filterPopupShow = true;
      console.log(this.filterPopupShow);
    },
    async getSchoolYears() {
      const apiUrl = "/api/year/school/?province=" + this.userInfo.province;
      const response = await this.$axios.get(apiUrl);
      if (response.data.errno === 0) {
        // 填报限制
        this.schoolNowYear = response.data.results.now_year + "";
        this.schoolYears = response.data.results.years;

        this.setHistoryYears();
      }
    },
    setHistoryYears() {
      let schoolTitle = [];
      this.schoolYears.forEach((year, index) => {
        schoolTitle.push({
          id: 410 + index,
          label: year + "",
          prop: "",
          width: ""
        });
      });
    },
    async getScorePiciTuijian() {
      const apiUrl = "/api/province/data/query/";
      let params = {
        province: this.userInfo && this.userInfo.province,
        point: this.userInfo && this.userInfo.point,
        type: this.userInfo && !!this.userInfo.type ? this.userInfo.type : ""
      };
      // console.log("4444----66", params);
      try {
        const res = await this.$axios.post(apiUrl, params);
        if (res.data.errno === 0) {
          const Data = res.data.results;
          this.piciInfo = Data;
          console.log(this.piciInfo);
        }
      } catch (error) {
        console.log(error);
      }
    },
    searchData() {
      this.maskShow = true;
      localStorage.removeItem("allSchoolData");
      localStorage.removeItem("localPage");
      this.pagination.currentPage = 1;
      localStorage.removeItem("localHasNext");
      localStorage.removeItem("localTotalPage");

      this.fetchSchoolsDataList();
    },
    async fetchSchoolsDataList() {
      if (this.userInfo && !this.userInfo.can_query) {
        this.pagination.pageSize = 6;
      } else {
        this.pagination.pageSize = 10;
      }

      localStorage.setItem("localPage", this.pagination.currentPage);

      const pageString = `?page=${this.pagination.currentPage}&page_size=${this.pagination.pageSize}&tag=${this.piciTag}`;
      let zone_name__icontains = "";
      let nature = "";
      let tags__icontains = "";
      let category__icontains = "";
      if (this.filtersOption) {
        if (this.filtersOption.city.length > 0) {
          zone_name__icontains = this.filtersOption.city.join(",");
        }
        if (this.filtersOption.xingzhi) {
          nature = this.filtersOption.xingzhi;
        }
        if (this.filtersOption.hot.length > 0) {
          tags__icontains = this.filtersOption.hot.join(",");
        }
        if (this.filtersOption.type.length > 0) {
          category__icontains = this.filtersOption.type.join(",");
        }
      }

      const queryString = `&nature=${nature}&tags__icontains=${tags__icontains}&zone_name__icontains=${zone_name__icontains}&category__icontains=${category__icontains}`;
      const apiUrl = `/api/school/query/${pageString}${queryString}`;
      try {
        let postData = {};
        if (this.userInfo && this.userInfo.is_new) {
          postData = {
            province: this.userInfo.province,
            point: this.userInfo.point,
            type: "",
            tag: "",
            position: this.userInfo.position,
            subjects: this.userInfo.subject_str.split("/"),
            year: this.currentYear,
            show_profess: true //获取院校加专业全量数据
          };
        } else {
          postData = {
            province: this.userInfo.province,
            point: this.userInfo.point,
            type: this.userInfo.type,
            tag: this.showScoreInfo.pici,
            year: this.currentYear,
            subjects: []
          };
        }

        if (this.filterValue == 1) {
          //院校
          postData.school_key = this.searchValue;
        } else {
          // 专业
          postData.professional_key = this.searchValue;
        }
        const response = await this.$axios.post(apiUrl, postData);
        if (response.data.errno === 0) {
          this.maskShow = false;
          this.pagination.total = response.data.results.count;
          this.hasNext = response.data.results.has_next;

          localStorage.setItem("localHasNext", this.hasNext);
          localStorage.setItem("localTotalPage", this.pagination.total);

          if (response.data.results.results.length > 0) {
            this.dealData(response.data.results.results);
            if (this.isFilter) {
              if (response.data.results.results.length > 5) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
              }
            }

            const Data = JSON.parse(localStorage.getItem("allSchoolData"));
            this.tableData = _.unionBy(
              Data,
              response.data.results.results,
              "id"
            );

            localStorage.setItem(
              "allSchoolData",
              JSON.stringify(this.tableData)
            );
          } else {
            this.tableData = [];
            if (this.isFilter) {
              this.$toast("没有符合条件的学校");
            } else {
              this.$toast("没有查找到学校");
            }
          }
        } else {
          this.maskShow = false;
        }
      } catch (error) {
        this.maskShow = false;
        console.log(`question error: ${error}`);
      }
    },
    dealData(data) {
      data.forEach((item, index) => {
        if (this.userInfo && !this.userInfo.is_new) {
          this.$set(item, "expand", false);
          this.$set(item, "zhiyuan", "");
          this.$set(item, "majorData", []);
          this.$set(item, "isSelect", false);
        }

        if (item.professionals && item.professionals.length > 0) {
          item.professionals.forEach((mItem, mIndex) => {
            if (!mItem["isSelect"]) {
              this.$set(mItem, "isSelect", false);
            }
          });
        }
      });
    },
    getFilters(data) {
      // console.log("筛选条件", data);
      for (let i in data) {
        if ((Array.isArray(data[i]) && data[i].length > 0) || !!data[i]) {
          this.isFilter = true;
          localStorage.removeItem("allSchoolData");
          localStorage.removeItem("localPage");
          this.pagination.currentPage = 1;
          localStorage.removeItem("localHasNext");
          localStorage.removeItem("localTotalPage");
        } else {
          this.isFilter = false;
        }
      }
      // console.log("isFilter----", this.isFilter);

      this.maskShow = true;
      this.filtersOption = data;
      this.fetchSchoolsDataList();
    },
    editScore() {
      this.$router.push({ path: "/fillScore/update" });
    },
    editPici() {
      this.$router.push({ path: "/changePici" });
    },
    handleClickMore() {
      this.maskShow = true;
      this.pagination.currentPage += 1;
      localStorage.setItem("localPage", this.pagination.currentPage);
      this.fetchSchoolsDataList();
    }
  }
};
</script>

<style lang="scss" scoped>
.vip_fill_page {
  .content {
    padding-bottom: 0 !important;
  }
}
.vip_new_fill_page {
  .content {
    padding-bottom: 1.5rem !important;
  }
}
.fill_table_page {
  .van-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(26, 26, 26, 0.01);
  }
  .fill_page_head {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 100;
    color: #666;
    display: flex;
    align-items: center;
    height: 0.88rem;
    padding: 0 0.4rem;
    background: #fff;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    span {
      margin-right: 10px;
    }
    a {
      border: 1px solid #a50000;
      border-radius: 3px;
      padding: 4px;
      margin-left: 10px;
    }
  }
  .new_head {
    display: flex;
    align-items: center;
  }
  .content {
    overflow: hidden;
    border-radius: 5px;
    padding-top: 0.88rem;
    padding-bottom: 1.5rem;
    .filter_box {
      background: #fff;
      padding: 0.2rem;
      .search_value {
        input {
          padding: 6px 12px;
        }
        .el-input__prefix {
          right: 0;
          left: unset;
          width: 0.7rem;
          background: #a50000;
          border-radius: 0 4px 4px 0;
        }

        .el-input__icon {
          line-height: 100%;
          font-size: 17px;
          color: #e2e2e2;
        }
      }
    }
    .filter_type {
      display: flex;
      margin-bottom: 10px;
      > div {
        margin-right: 15px;
      }
    }
    .table_wrap {
      z-index: 1;
    }
    .more_data {
      margin: 0 0.32rem;
      text-align: center;
      height: 0.7rem;
      line-height: 0.7rem;
      color: #868686;
      background: #e4e4e4;
      border-radius: 50px;
      margin-bottom: 1rem;
    }
    .new_more_data {
      margin-bottom: 1.6rem;
    }
    .over_text {
      text-align: center;
      color: #868686;
      height: 0.8rem;
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
